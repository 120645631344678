import React from 'react'
import CTASection from '../../components/CTASection'
import Layout from '../../components/Layout'
import CaseStudyContainer from '../../components/story/CaseStudyContainer'
import ChocoBody from '../../components/story/prima/Content'
import ChocoHeader from '../../components/story/prima/Header'
import MetaImage from '../../assets/images/story/prima/cover.png'
import QuoteBlock from '../../components/blocks/QuoteBlock'

const PrimaPage = () => {
  return (
    <Layout
      title="Prima case study"
      description="The geographically distributed +200-person engineering organization at Prima uses Swarmia to monitor and improve key engineering metrics and start better conversations in and between teams."
      metaImage={MetaImage}
    >
      <CaseStudyContainer>
        <ChocoHeader />
        <QuoteBlock person="tatiana" />
        <ChocoBody />
      </CaseStudyContainer>
      <CTASection>
        Swarmia is how the best teams get better. Get started today.
      </CTASection>
    </Layout>
  )
}
export default PrimaPage
