import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Header from '../Header'

const PrimaHeader = ({ featured }: { featured?: boolean }) => {
  const data = useStaticQuery(query)
  const photo = data.hero.childImageSharp.gatsbyImageData
  return (
    <Header
      photo={photo}
      company="Prima"
      heading="Boosting visibility, transparency, and engineering productivity at Prima"
      subheading="The geographically distributed +200-person engineering organization at Prima uses Swarmia to monitor and improve key engineering metrics and start better conversations in and between teams."
      featured={featured}
    />
  )
}

export default PrimaHeader

const query = graphql`
  {
    hero: file(relativePath: { eq: "images/story/prima/hero.png" }) {
      childImageSharp {
        gatsbyImageData(width: 480, height: 540, layout: FIXED)
      }
    }
  }
`
